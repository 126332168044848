import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isOwner
        ? _c(
            VIcon,
            {
              attrs: { disabled: _vm.disableMergeIcon },
              on: {
                click: function ($event) {
                  return _vm.getInstructionDiffList()
                },
              },
            },
            [_vm._v("mdi-source-merge")]
          )
        : _vm._e(),
      _c(
        VDialog,
        {
          key: _vm.keyforRerender,
          attrs: { persistent: "", width: "1500" },
          model: {
            value: _vm.openInstructionList,
            callback: function ($$v) {
              _vm.openInstructionList = $$v
            },
            expression: "openInstructionList",
          },
        },
        [
          _c(
            VCardTitle,
            {
              staticStyle: {
                width: "inherit",
                "background-color": "#0097e6",
                color: "white",
              },
            },
            [
              _c(VIcon, { attrs: { color: "white" } }, [
                _vm._v("mdi-source-merge"),
              ]),
              _vm._v(" Merge Instruction"),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c(
                VCard,
                { staticStyle: { width: "auto" } },
                [
                  _c(
                    VList,
                    { attrs: { dense: "" } },
                    [
                      _c(VSubheader, [_vm._v("Copied ClassList")]),
                      _c(
                        VListItemGroup,
                        {
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.selectListNumber,
                            callback: function ($$v) {
                              _vm.selectListNumber = $$v
                            },
                            expression: "selectListNumber",
                          },
                        },
                        _vm._l(_vm.copyClassList, function (copyClassInfo, i) {
                          return _c(
                            VListItem,
                            {
                              key: i,
                              on: {
                                click: function ($event) {
                                  return _vm.loadLabInstruction(
                                    copyClassInfo,
                                    i
                                  )
                                },
                              },
                            },
                            [
                              !copyClassInfo.userImage
                                ? _c(
                                    VListItemIcon,
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          staticStyle: {
                                            width: "42px",
                                            height: "40px",
                                            margin: "0 auto",
                                            "margin-right": "-20px",
                                          },
                                          attrs: { "x-large": "", id: "test" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        mdi-account-circle\n                                    "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    VListItemAvatar,
                                    [
                                      _c(
                                        VAvatar,
                                        {
                                          staticStyle: {
                                            "margin-right": "3px",
                                          },
                                          attrs: { size: "37", id: "test" },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              alt: "Avatar",
                                              src: copyClassInfo.userImage,
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                              _c(
                                VListItemContent,
                                [
                                  _c(VListItemTitle, {
                                    domProps: {
                                      textContent: _vm._s(
                                        copyClassInfo.userName
                                      ),
                                    },
                                  }),
                                  _c(VListItemTitle, {
                                    domProps: {
                                      textContent: _vm._s(
                                        copyClassInfo.className
                                      ),
                                    },
                                  }),
                                  _c(VListItemSubtitle, {
                                    domProps: {
                                      textContent: _vm._s(
                                        copyClassInfo.timeStamp
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VCard,
                { staticStyle: { width: "inherit", height: "600px" } },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { "text-align": "center", width: "50%" },
                        },
                        [_c("b", [_vm._v("Base")])]
                      ),
                      _c(
                        VTooltip,
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    VIcon,
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.MergeAll(_vm.cmOption)
                                            },
                                          },
                                        },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v("mdi-arrow-left")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [_c("span", [_vm._v("Merge All")])]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: { "text-align": "center", width: "50%" },
                        },
                        [_c("b", [_vm._v("Branch")])]
                      ),
                    ],
                    1
                  ),
                  _c("codemirror", {
                    staticClass: "merge-instruction",
                    attrs: { merge: true, options: _vm.cmOption },
                    on: {
                      input: _vm.setNewInstruction,
                      scroll: _vm.onCmScroll,
                    },
                  }),
                  _c(
                    "div",
                    { staticStyle: { float: "right", "margin-top": "20px" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.CancelMerge()
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        VBtn,
                        {
                          staticStyle: { "margin-right": "7px" },
                          attrs: {
                            disabled: _vm.disableMergeBtn,
                            color: "primary",
                            text: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.mergeInstruction()
                            },
                          },
                        },
                        [_vm._v("Merge")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }