import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.small
    ? _c("div", { staticStyle: { height: "100%", width: "100%" } }, [
        !_vm.inSideElectron
          ? _c(
              "div",
              {
                staticStyle: {
                  position: "absolute",
                  width: "600px",
                  left: "50%",
                  top: "10%",
                  "margin-left": "-200px",
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { margin: "50px 0 50px 0" } },
                  [
                    _c("h2", { staticStyle: { "margin-bottom": "10px" } }, [
                      _vm._v("Electron을 사용하여 진행되는 랩입니다."),
                    ]),
                    _vm._m(0),
                    !_vm.inSideElectron
                      ? _c(
                          VBtn,
                          {
                            staticStyle: { height: "45px", width: "130px" },
                            attrs: {
                              href: _vm.setDirectURL,
                              color: "green",
                              depressed: "",
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { color: "white" } },
                              [
                                _c(
                                  VRow,
                                  [
                                    _c(
                                      VIcon,
                                      {
                                        staticStyle: { "margin-right": "5px" },
                                        attrs: { large: "" },
                                      },
                                      [_vm._v("mdi-atom")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "text-align": "left",
                                          "margin-top": "2px",
                                        },
                                      },
                                      [
                                        _vm._v("Start"),
                                        _c("br"),
                                        _vm._v("Electron"),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(VDivider, {
                  staticStyle: {
                    position: "relative",
                    "min-width": "600px",
                    "margin-left": "-20%",
                    "margin-bottom": "50px",
                  },
                }),
                _vm._m(1),
                _c(
                  VBtn,
                  {
                    staticStyle: {
                      "margin-right": "30px",
                      height: "45px",
                      width: "130px",
                    },
                    attrs: {
                      href: "https://github.com/msa-ez/msa-ez.github.io/releases/download/v2.0/MSAEasy-0.1.0.dmg",
                      depressed: "",
                      download: "",
                      color: "primary",
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticStyle: { color: "white" } },
                      [
                        _c(
                          VRow,
                          [
                            _c(
                              VIcon,
                              {
                                staticStyle: { margin: "0 5px 0 -15px" },
                                attrs: { large: "" },
                              },
                              [_vm._v("mdi-apple")]
                            ),
                            _c(
                              "div",
                              { staticStyle: { "line-height": "45px" } },
                              [_vm._v("For Mac")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  VBtn,
                  {
                    staticStyle: {
                      "margin-right": "30px",
                      height: "45px",
                      width: "130px",
                    },
                    attrs: {
                      href: "https://github.com/msa-ez/msa-ez.github.io/releases/download/v2.0/MSAEasy.Setup.0.1.0.exe",
                      depressed: "",
                      download: "",
                      color: "primary",
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticStyle: { color: "white" } },
                      [
                        _c(
                          VRow,
                          [
                            _c("Icon", {
                              staticStyle: {
                                width: "30px",
                                height: "30px",
                                "margin-right": "5px",
                                "margin-top": "2px",
                              },
                              attrs: { icon: "cib:windows" },
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "left",
                                  "margin-top": "2px",
                                },
                              },
                              [_vm._v("For"), _c("br"), _vm._v("Windows")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                !_vm.ideUrl && _vm.loadUrl
                  ? _c(VProgressCircular, {
                      staticStyle: {
                        "margin-top": "25%",
                        color: "#1976d2 !important",
                      },
                      attrs: { size: 130, color: "primary", indeterminate: "" },
                    })
                  : _vm._e(),
                !_vm.ideUrl
                  ? _c("div")
                  : _c("vue-friendly-iframe", {
                      staticStyle: { height: "100%", width: "100%" },
                      attrs: {
                        src: _vm.ideUrl,
                        frameborder: "0",
                        gesture: "media",
                        allow: "encrypted-media",
                      },
                    }),
              ],
              1
            ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          "margin-bottom": "10px",
          "font-size": "13px",
          opacity: "0.7",
        },
      },
      [
        _vm._v(
          "\n                만약 자동으로 랩이 열리지 않을때는 해당 Lab id"
        ),
        _c("br"),
        _vm._v(
          "\n                (class id 를 확장하여 바로 Lab 이 열릴 수 있도록 id 를 확장) 을 입력하세요."
        ),
        _c("br"),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
      _c("h2", { staticStyle: { "margin-bottom": "10px" } }, [
        _vm._v("Download Electron"),
      ]),
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            "font-size": "13px",
            opacity: "0.7",
          },
        },
        [_vm._v("아래의 버튼을 클릭하여 다운로드 하세요.")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }