var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.sourceHtml
        ? _c("v-runtime-template", {
            attrs: {
              template: "<div slot=default>" + _vm.sourceHtml + "</div>",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }