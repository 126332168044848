import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticStyle: { "overflow-y": "hidden" } },
    [
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.chatStatus
            ? _c(
                VCard,
                {
                  class: { classRoom: _vm.initUser, labs: !_vm.initUser },
                  staticStyle: { "z-index": "999", border: "solid 1px" },
                  attrs: {
                    outlined: "",
                    align: "left",
                    width: _vm.selectedUser ? "100%" : "28%",
                    "min-height": "440px",
                    "min-width": "380px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        float: "right",
                        "margin-top": "10px",
                        "margin-right": "5px",
                        "margin-bottom": "5px",
                      },
                      attrs: { align: "right" },
                    },
                    [
                      !_vm.selectedUser
                        ? _c(
                            VIcon,
                            {
                              attrs: { text: "" },
                              on: { click: _vm.closeChat },
                            },
                            [_vm._v("mdi-close")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    VTabs,
                    {
                      staticStyle: {
                        display: "block",
                        width: "100%",
                        "text-align": "center",
                        "border-bottom": "1px solid",
                      },
                      attrs: {
                        "center-active": "",
                        centered: "",
                        "hide-slider": "",
                      },
                      on: { change: _vm.changeRoom },
                      model: {
                        value: _vm.chatRoom,
                        callback: function ($$v) {
                          _vm.chatRoom = $$v
                        },
                        expression: "chatRoom",
                      },
                    },
                    [
                      _vm._l(_vm.chatRoomList, function (n) {
                        return _c(
                          VTab,
                          { key: n },
                          [
                            _vm.unReadMessage(n) > 0
                              ? _c(
                                  VAvatar,
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      right: "1px",
                                      top: "1px",
                                    },
                                    attrs: { color: "green", size: "22" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(_vm.unReadMessage(n)) +
                                        "\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(
                              "\n                    " +
                                _vm._s(n) +
                                "\n                "
                            ),
                          ],
                          1
                        )
                      }),
                      _c("div"),
                    ],
                    2
                  ),
                  _c(
                    VCardText,
                    {
                      staticClass: "chatBlock",
                      staticStyle: {
                        overflow: "auto",
                        height: "630px",
                        "overflow-x": "hidden",
                      },
                      style: _vm.selectedUser
                        ? "max-height: 630px;"
                        : "max-height: 74.5%;",
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _vm.loadChatAll[_vm.chatRoom] && !_vm.loadChat
                            ? _c(
                                VBtn,
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getmoreChat()
                                    },
                                  },
                                },
                                [_vm._v("더보기")]
                              )
                            : _vm._e(),
                          _vm.loadChat
                            ? _c(VProgressCircular, {
                                attrs: { indeterminate: "", color: "primary" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._l(_vm.filteredChat, function (item, idx) {
                        return item.userId
                          ? _c(
                              "div",
                              {
                                on: {
                                  contextmenu: function ($event) {
                                    $event.preventDefault()
                                    return _vm.contextOpen($event, item, idx)
                                  },
                                },
                              },
                              [
                                item.userId == _vm.connectUserId
                                  ? _c(
                                      "div",
                                      {
                                        class: item.id,
                                        staticStyle: {
                                          width: "100%",
                                          display: "block",
                                          "text-align": "right",
                                          float: "right",
                                        },
                                      },
                                      [
                                        item.divider && item.today
                                          ? _c(
                                              "span",
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(item.today.Year) +
                                                      "년 " +
                                                      _vm._s(item.today.Month) +
                                                      "월 " +
                                                      _vm._s(item.today.Day) +
                                                      "일"
                                                  ),
                                                ]),
                                                _c(VDivider),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "caption",
                                            staticStyle: {
                                              display: "inline-block",
                                              width: "60px",
                                              height: "20px",
                                              "margin-bottom": "-15px",
                                              "margin-top": "15px",
                                              "text-align": "center",
                                            },
                                            style: _vm.selectedUser
                                              ? "margin-left: 84%"
                                              : "margin-left: 84%",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(item.timeStamp)
                                            ),
                                          ]
                                        ),
                                        item.image
                                          ? _c(
                                              VCard,
                                              {
                                                staticStyle: {
                                                  width: "80%",
                                                  "margin-left": "21%",
                                                  "margin-bottom": "10px",
                                                  padding: "5px",
                                                  "background-color": "#ADE6B3",
                                                },
                                                style: item.replyClass
                                                  ? "height:162px"
                                                  : "",
                                              },
                                              [
                                                _c(VImg, {
                                                  staticStyle: {
                                                    "background-color": "white",
                                                  },
                                                  attrs: { src: item.image },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.chatImg(
                                                        item.image
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("transition", [
                                          item.message && !item.replyUserEmail
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "balloon_03",
                                                  staticStyle: {
                                                    "white-space": "pre-wrap",
                                                    display: "inline-block",
                                                    "text-align": "left",
                                                    "margin-top": "0px",
                                                    "margin-bottom": "35px",
                                                  },
                                                  on: {
                                                    dblclick: function (
                                                      $event
                                                    ) {
                                                      return _vm.beforeReply(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("div", {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.message
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              )
                                            : item.message &&
                                              item.replyMessage &&
                                              !item.replyImage
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "balloon_03",
                                                  staticStyle: {
                                                    "white-space": "pre-wrap",
                                                    display: "inline-block",
                                                    "text-align": "left",
                                                  },
                                                  on: {
                                                    dblclick: function (
                                                      $event
                                                    ) {
                                                      return _vm.beforeReply(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "border-bottom":
                                                          "1px solid black",
                                                        "padding-bottom": "5px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.viewMessage(
                                                            item.replyClass
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption reusername",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.replyUserName
                                                            ) + "(님) 에게 답장"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        _vm._s(
                                                          item.replyMessage
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("div", {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "padding-top": "5px",
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.message
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              )
                                            : item.message && item.replyImage
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "balloon_03",
                                                  staticStyle: {
                                                    "white-space": "pre-wrap",
                                                    display: "inline-block",
                                                    "text-align": "left",
                                                    "margin-top": "0",
                                                  },
                                                  on: {
                                                    dblclick: function (
                                                      $event
                                                    ) {
                                                      return _vm.beforeReply(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "border-bottom":
                                                          "1px solid black",
                                                        "padding-bottom": "5px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.viewMessage(
                                                            item.replyClass
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption reusername",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.replyUserName
                                                            ) + "(님) 에게 답장"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        _vm._s(
                                                          item.replyMessage
                                                        ) +
                                                          "\n                                    "
                                                      ),
                                                      _c(VImg, {
                                                        staticStyle: {
                                                          "background-color":
                                                            "white",
                                                        },
                                                        style: item.replyMessage
                                                          ? "margin-top: -20px;"
                                                          : "margin-top: -30px;",
                                                        attrs: {
                                                          width: "100",
                                                          src: item.replyImage,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.chatImg(
                                                              item.replyImage
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c("div", {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "padding-top": "5px",
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.message
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              )
                                            : item.classroomMessage &&
                                              item.replyMessage
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "balloon_03",
                                                  staticStyle: {
                                                    "white-space": "pre-wrap",
                                                    display: "inline-block",
                                                    "text-align": "left",
                                                  },
                                                  on: {
                                                    dblclick: function (
                                                      $event
                                                    ) {
                                                      return _vm.beforeReply(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "border-bottom":
                                                          "1px solid black",
                                                        "padding-bottom": "5px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.viewMessage(
                                                            item.replyClass
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption reusername",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.replyUserName
                                                            ) + "(님) 에게 답장"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        _vm._s(
                                                          item.replyMessage
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("div", {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "padding-top": "5px",
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.classroomMessage
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              )
                                            : item.replyUserEmail &&
                                              item.classroomMessage
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "balloon_03",
                                                  staticStyle: {
                                                    "white-space": "pre-wrap",
                                                    display: "inline-block",
                                                    "text-align": "left",
                                                    "margin-top": "0px",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "caption reusername",
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "10px",
                                                          display: "block",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.replyUserName
                                                          ) + "(님) 에게 답장"
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    _vm._s(
                                                      item.classroomMessage
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "clearfix",
                                        on: {
                                          contextmenu: function ($event) {
                                            $event.preventDefault()
                                            return _vm.contextOpen(
                                              $event,
                                              item,
                                              idx
                                            )
                                          },
                                        },
                                      },
                                      [
                                        item.divider && item.today
                                          ? _c(
                                              "span",
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(item.today.Year) +
                                                      "년 " +
                                                      _vm._s(item.today.Month) +
                                                      "월 " +
                                                      _vm._s(item.today.Day) +
                                                      "일"
                                                  ),
                                                ]),
                                                _c(VDivider),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "caption",
                                            staticStyle: {
                                              display: "block",
                                              width: "200px",
                                              height: "15px",
                                              "margin-top": "20px",
                                              "margin-bottom": "-15px",
                                              "text-align": "left",
                                            },
                                            style: _vm.selectedUser
                                              ? "margin-left: 50px"
                                              : "margin-left:45px",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.userName) +
                                                " - " +
                                                _vm._s(item.timeStamp) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                        _vm._l(
                                          _vm.enrolledUserPhotodata,
                                          function (user) {
                                            return _c("div", [
                                              user.email == item.userId
                                                ? _c("div", [
                                                    user.photoURL
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              VAvatar,
                                                              {
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "3px",
                                                                },
                                                                attrs: {
                                                                  size: "37",
                                                                  id: "test",
                                                                },
                                                              },
                                                              [
                                                                _c("img", {
                                                                  attrs: {
                                                                    alt: "Avatar",
                                                                    src: user.photoURL,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.chatImg(
                                                                          item.image
                                                                        )
                                                                      },
                                                                    dblclick:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.beforeReply(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              VIcon,
                                                              {
                                                                staticStyle: {
                                                                  width: "40px",
                                                                  height:
                                                                    "40px",
                                                                  margin:
                                                                    "0 auto",
                                                                },
                                                                attrs: {
                                                                  "x-large": "",
                                                                  id: "test",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                        mdi-account-circle\n                                    "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                  ])
                                                : _vm._e(),
                                            ])
                                          }
                                        ),
                                        item.image
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  VAvatar,
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "3px",
                                                      "margin-bottom": "-60px",
                                                      top: "-30px",
                                                    },
                                                    attrs: {
                                                      size: "37",
                                                      id: "Help",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        alt: "Avatar",
                                                        src: item.image,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.chatImg(
                                                            item.image
                                                          )
                                                        },
                                                        dblclick: function (
                                                          $event
                                                        ) {
                                                          return _vm.beforeReply(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        item.isTyping
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "balloon_04",
                                                staticStyle: {
                                                  "white-space": "pre-wrap",
                                                  display: "inline-block",
                                                  "text-align": "left",
                                                },
                                              },
                                              [
                                                _c(
                                                  "vue-typed-js",
                                                  {
                                                    attrs: {
                                                      strings: [item.message],
                                                      typeSpeed: 40,
                                                      showCursor: false,
                                                    },
                                                    on: {
                                                      onComplete: function (
                                                        $event
                                                      ) {
                                                        item.isTyping = false
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass: "typing",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "transition",
                                              { staticClass: "clearfix" },
                                              [
                                                item.message &&
                                                !item.replyUserEmail
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "balloon_04",
                                                        staticStyle: {
                                                          "white-space":
                                                            "pre-wrap",
                                                          display:
                                                            "inline-block",
                                                          "text-align": "left",
                                                        },
                                                        on: {
                                                          dblclick: function (
                                                            $event
                                                          ) {
                                                            return _vm.beforeReply(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticStyle: {
                                                            "text-align":
                                                              "left",
                                                          },
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              item.message
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : item.message &&
                                                    item.replyMessage &&
                                                    !item.replyImage
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "balloon_04",
                                                        staticStyle: {
                                                          "white-space":
                                                            "pre-wrap",
                                                          display:
                                                            "inline-block",
                                                          "text-align": "left",
                                                        },
                                                        on: {
                                                          dblclick: function (
                                                            $event
                                                          ) {
                                                            return _vm.beforeReply(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "border-bottom":
                                                                "1px solid black",
                                                              "padding-bottom":
                                                                "5px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.viewMessage(
                                                                  item.replyClass
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "caption reusername",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.replyUserName
                                                                  ) +
                                                                    "(님) 에게 답장"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              _vm._s(
                                                                item.replyMessage
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-top":
                                                              "5px",
                                                          },
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              item.message
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : item.message &&
                                                    item.replyImage
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "balloon_04",
                                                        staticStyle: {
                                                          "white-space":
                                                            "pre-wrap",
                                                          display:
                                                            "inline-block",
                                                          "text-align": "left",
                                                        },
                                                        on: {
                                                          dblclick: function (
                                                            $event
                                                          ) {
                                                            return _vm.beforeReply(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "border-bottom":
                                                                "1px solid black",
                                                              "padding-bottom":
                                                                "5px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.viewMessage(
                                                                  item.replyClass
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "caption reusername",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.replyUserName
                                                                  ) +
                                                                    "(님) 에게 답장"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              _vm._s(
                                                                item.replyMessage
                                                              ) +
                                                                "\n                                "
                                                            ),
                                                            _c(VImg, {
                                                              staticStyle: {
                                                                "background-color":
                                                                  "white",
                                                              },
                                                              style:
                                                                item.replyMessage
                                                                  ? "margin-top: -20px;"
                                                                  : "margin-top: -30px;",
                                                              attrs: {
                                                                width: "100",
                                                                src: item.replyImage,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.chatImg(
                                                                      item.replyImage
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "text-align":
                                                              "left",
                                                            "padding-top":
                                                              "5px",
                                                          },
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              item.message
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : item.classroomMessage &&
                                                    item.replyMessage
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "balloon_04",
                                                        staticStyle: {
                                                          "white-space":
                                                            "pre-wrap",
                                                          display:
                                                            "inline-block",
                                                          "text-align": "left",
                                                        },
                                                        on: {
                                                          dblclick: function (
                                                            $event
                                                          ) {
                                                            return _vm.beforeReply(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "border-bottom":
                                                                "1px solid black",
                                                              "padding-bottom":
                                                                "5px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.viewMessage(
                                                                  item.replyClass
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "caption reusername",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.replyUserName
                                                                  ) +
                                                                    "(님) 에게 답장"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              _vm._s(
                                                                item.replyMessage
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-top":
                                                              "5px",
                                                          },
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              item.classroomMessage
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : item.replyUserEmail &&
                                                    item.classroomMessage
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "balloon_04",
                                                        staticStyle: {
                                                          "white-space":
                                                            "pre-wrap",
                                                          display:
                                                            "inline-block",
                                                          "text-align": "left",
                                                        },
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "caption reusername",
                                                              staticStyle: {
                                                                "margin-bottom":
                                                                  "10px",
                                                                display:
                                                                  "block",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.replyUserName
                                                                ) +
                                                                  "(님) 에게 답장"
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          _vm._s(
                                                            item.classroomMessage
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                      ],
                                      2
                                    ),
                              ]
                            )
                          : _vm._e()
                      }),
                      _c(
                        "vue-context",
                        { ref: "menu", on: { open: _vm.onOpen } },
                        [
                          _c("li", [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.onClick($event.target.innerText)
                                  },
                                },
                              },
                              [_vm._v("답장")]
                            ),
                          ]),
                          _c("li", [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.onClick($event.target.innerText)
                                  },
                                },
                              },
                              [_vm._v("랩실 보기")]
                            ),
                          ]),
                          _c("li", [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.onClick($event.target.innerText)
                                  },
                                },
                              },
                              [_vm._v("복사")]
                            ),
                          ]),
                          _vm.answerList && _vm.isAdmin
                            ? _c("li", { staticClass: "v-context__sub" }, [
                                _c("a", [_vm._v("답")]),
                                _c("ul", { staticClass: "v-context" }, [
                                  _c(
                                    "li",
                                    _vm._l(
                                      _vm.answerMenuList,
                                      function (answer) {
                                        return _c(
                                          "a",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickAnswer(
                                                  answer.route
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(answer.errName) +
                                                ":\n                                    " +
                                                _vm._s(answer.route)
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    2
                  ),
                  _vm.reply
                    ? _c(
                        "div",
                        [
                          _c(
                            VCard,
                            {
                              staticStyle: {
                                "margin-left": "5px",
                                "margin-right": "5px",
                                "background-color": "#dfe6e9",
                                position: "relative",
                                display: "block",
                                "padding-bottom": "8px",
                              },
                              style: _vm.selectedUser
                                ? "margin-bottom:-40px;"
                                : "margin-top:-50px;",
                            },
                            [
                              _c(
                                VIcon,
                                {
                                  staticStyle: {
                                    display: "block",
                                    float: "right",
                                  },
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.reply = null
                                    },
                                  },
                                },
                                [_vm._v("mdi-close")]
                              ),
                              _c(
                                "em",
                                {
                                  staticStyle: {
                                    color: "#0984e3",
                                    "margin-left": "10px",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.reply.userName))]
                              ),
                              _vm._v(" (님) 에게 답장 "),
                              _c("br"),
                              _vm.reply.message.length > 19
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: { "margin-left": "12px" },
                                      style: _vm.reply.image
                                        ? "margin-left:70px;"
                                        : "",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.reply.message.substring(0, 19)
                                        ) + "..."
                                      ),
                                    ]
                                  )
                                : _c("span", {
                                    staticStyle: { "margin-left": "12px" },
                                    domProps: {
                                      innerHTML: _vm._s(_vm.reply.message),
                                    },
                                  }),
                              _vm.reply.image
                                ? _c(VImg, {
                                    staticStyle: {
                                      "margin-top": "-20px",
                                      "margin-left": "10px",
                                      border: "solid 1px",
                                      "background-color": "white",
                                    },
                                    attrs: {
                                      width: "50",
                                      src: _vm.reply.image,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.chatImg(_vm.reply.image)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticStyle: { height: "20%" } },
                    [
                      _c(
                        VCardActions,
                        {
                          staticStyle: {
                            height: "105px",
                            display: "block",
                            width: "100%",
                            position: "relative",
                          },
                          style: _vm.selectedUser
                            ? "bottom:-35px;"
                            : "bottom:5px;",
                        },
                        [
                          _vm.reply
                            ? _c(
                                VIcon,
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    top: "25px",
                                  },
                                  attrs: { small: "" },
                                },
                                [
                                  _vm._v(
                                    "mdi-subdirectory-arrow-right\n                    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.chatRoom == 0 && _vm.isAdmin
                            ? _c(VTextarea, {
                                staticStyle: { overflow: "auto" },
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "row-height": "25",
                                  "no-resize": "",
                                  clearable: "",
                                  "append-icon": "mdi-camera",
                                  "prepend-icon": _vm.chatBellOff
                                    ? "mdi-bell-off"
                                    : "mdi-bell",
                                },
                                on: {
                                  "click:prepend": function ($event) {
                                    _vm.chatBellOff = !_vm.chatBellOff
                                  },
                                  "click:append": function ($event) {
                                    return _vm.screenCapture()
                                  },
                                  keypress: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    if (!$event.altKey) return null
                                    return _vm.lineBreak()
                                  },
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    if (
                                      $event.ctrlKey ||
                                      $event.shiftKey ||
                                      $event.altKey ||
                                      $event.metaKey
                                    )
                                      return null
                                    return _vm.postChatMessage()
                                  },
                                },
                                model: {
                                  value: _vm.message,
                                  callback: function ($$v) {
                                    _vm.message = $$v
                                  },
                                  expression: "message",
                                },
                              })
                            : _vm.chatRoom == 0 && !_vm.isAdmin
                            ? _c("div")
                            : _c(VTextarea, {
                                staticStyle: { overflow: "auto" },
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "row-height": "25",
                                  "no-resize": "",
                                  "append-icon": "mdi-camera",
                                  "prepend-icon": _vm.chatBellOff
                                    ? "mdi-bell-off"
                                    : "mdi-bell",
                                },
                                on: {
                                  "click:prepend": function ($event) {
                                    _vm.chatBellOff = !_vm.chatBellOff
                                  },
                                  "click:append": function ($event) {
                                    return _vm.screenCapture()
                                  },
                                  keypress: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    if (!$event.altKey) return null
                                    return _vm.lineBreak()
                                  },
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    if (
                                      $event.ctrlKey ||
                                      $event.shiftKey ||
                                      $event.altKey ||
                                      $event.metaKey
                                    )
                                      return null
                                    return _vm.postChatMessage()
                                  },
                                },
                                model: {
                                  value: _vm.message,
                                  callback: function ($$v) {
                                    _vm.message = $$v
                                  },
                                  expression: "message",
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.chatStatus && _vm.capturing
            ? _c(
                VCard,
                {
                  staticStyle: {
                    "z-index": "1000",
                    position: "absolute",
                    display: "block",
                    bottom: "100px",
                  },
                  attrs: {
                    color: "primary",
                    width: "24%",
                    "min-width": "345px",
                  },
                },
                [
                  !_vm.isCapture
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-top": "5px",
                            "margin-right": "5px",
                            "margin-bottom": "5px",
                          },
                          attrs: { align: "right" },
                        },
                        [
                          _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          VIcon,
                                          _vm._g(
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                              attrs: {
                                                bind: "attrs",
                                                color: "white",
                                                text: "",
                                                depressed: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.postChatMessage()
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                            mdi-file-send\n                        "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1392104920
                              ),
                            },
                            [_c("span", [_vm._v("이미지 전송")])]
                          ),
                          _c(
                            VIcon,
                            {
                              attrs: { color: "white", text: "" },
                              on: {
                                click: function ($event) {
                                  ;(_vm.tmpImage = null),
                                    (_vm.capturing = false)
                                },
                              },
                            },
                            [_vm._v("mdi-close")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    VCardTitle,
                    {
                      staticStyle: {
                        position: "relative",
                        "font-size": "20px",
                        height: "30px",
                        "line-height": "0",
                        color: "white",
                      },
                    },
                    [_vm._v("Preview\n            ")]
                  ),
                  _vm.isCapture
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-bottom": "30px",
                            "margin-left": "20px",
                          },
                        },
                        [
                          _c(VProgressCircular, {
                            attrs: { indeterminate: "", color: "white" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isCapture
                    ? _c(
                        VCardText,
                        {
                          staticStyle: {
                            width: "365px",
                            height: "195px",
                            "margin-left": "-10px",
                            "margin-right": "-100px",
                            padding: "30px",
                            "margin-bottom": "40px",
                            "margin-top": "-20px",
                          },
                        },
                        [
                          _c(VImg, {
                            staticStyle: {
                              height: "180px",
                              "background-color": "white",
                            },
                            attrs: { src: _vm.tmpImage },
                            nativeOn: {
                              click: function ($event) {
                                _vm.tmpImgDialog = true
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          !_vm.chatStatus
            ? _c(
                VBtn,
                {
                  staticStyle: { bottom: "10px" },
                  attrs: {
                    fab: "",
                    large: "",
                    primary: "",
                    bottom: "",
                    left: "",
                    absolute: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.chatManager()
                    },
                  },
                },
                [
                  _c(VIcon, { attrs: { color: "primary" } }, [
                    _vm._v("mdi-chat"),
                  ]),
                  _vm.newMessage
                    ? _c(
                        VAvatar,
                        {
                          staticClass: "newMessage",
                          style:
                            _vm.newMessageCnt < 99
                              ? "position: absolute; right: -4px; top: -17px;"
                              : "position: absolute; right: -7px; top: -25px;",
                          attrs: {
                            color: "red",
                            size: _vm.newMessageCnt < 99 ? "22" : "32",
                          },
                        },
                        [
                          _vm._v(
                            "\n               " +
                              _vm._s(_vm.newMessageCnt) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { width: "1000" },
          model: {
            value: _vm.tmpImgDialog,
            callback: function ($$v) {
              _vm.tmpImgDialog = $$v
            },
            expression: "tmpImgDialog",
          },
        },
        [
          _c(VImg, {
            staticStyle: { "background-color": "white" },
            attrs: { src: _vm.tmpImage },
          }),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { width: "1000" },
          on: {
            "click:outside": function ($event) {
              return _vm.removeChatImg()
            },
          },
          model: {
            value: _vm.chatImgDialog,
            callback: function ($$v) {
              _vm.chatImgDialog = $$v
            },
            expression: "chatImgDialog",
          },
        },
        [
          _c(VImg, {
            staticStyle: { "background-color": "white" },
            attrs: { src: _vm.chatImage },
            on: {
              click: function ($event) {
                _vm.chatImgDialog = false
              },
            },
          }),
        ],
        1
      ),
      !_vm.chatBellOff
        ? _c(
            VSnackbar,
            {
              staticStyle: { "z-index": "999", "margin-left": "-7px" },
              style: _vm.chatStatus
                ? "margin-bottom: 67px; margin-left: 3px; display: block; height: 65px; max-width: 370px;"
                : "margin-top:-80px;",
              attrs: {
                timeout: 3000,
                color: _vm.chatStatus ? "#747d8c" : "primary",
              },
              model: {
                value: _vm.snackBar.show,
                callback: function ($$v) {
                  _vm.$set(_vm.snackBar, "show", $$v)
                },
                expression: "snackBar.show",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "block",
                    "max-width": "330px",
                    "margin-left": "-10px",
                    "white-space": "nowrap",
                    "text-overflow": "ellipsis",
                    overflow: "hidden",
                    width: "100%",
                    "-webkit-line-clamp": "1",
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.snackBar.Message) + "\n    ")]
              ),
              !_vm.chatStatus
                ? _c(
                    VIcon,
                    {
                      staticStyle: { "z-index": "999", float: "right" },
                      on: {
                        click: function ($event) {
                          return _vm.openChatRoom()
                        },
                      },
                    },
                    [_vm._v("mdi-import")]
                  )
                : _vm._e(),
              _vm.chatStatus
                ? _c(
                    VIcon,
                    {
                      staticStyle: {
                        "z-index": "999",
                        float: "right",
                        position: "absolute",
                        left: "330px",
                        bottom: "2px",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showMessage()
                        },
                      },
                    },
                    [_vm._v("mdi-arrow-down")]
                  )
                : _vm._e(),
              _vm.snackBar.Img
                ? _c("div", [
                    _c("br"),
                    _c("img", {
                      staticStyle: {
                        width: "80%",
                        "background-color": "white",
                      },
                      attrs: { src: _vm.snackBar.Img },
                    }),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }