import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticStyle: { height: "100%", padding: "0px" }, attrs: { fluid: "" } },
    [
      _vm.naviIndex == 0
        ? _c(
            VTooltip,
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _vm.isAdmin || _vm.myId == _vm.userId
                          ? _c(
                              VBtn,
                              _vm._g(
                                _vm._b(
                                  {
                                    staticStyle: {
                                      position: "fixed",
                                      left: "15px",
                                      bottom: "15px",
                                      "z-index": "1",
                                    },
                                    attrs: {
                                      color: "primary",
                                      icon: "",
                                      large: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.resizeGuide()
                                      },
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#1976d2",
                                      "font-weight": "900",
                                      "font-size": "26px",
                                      "margin-top": "-4px",
                                    },
                                  },
                                  [_vm._v("↹")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                false,
                2212513189
              ),
            },
            [_c("span", [_vm._v("인스트럭션 크기 조절")])]
          )
        : _vm._e(),
      _vm.labInfo && _vm.labInfo.layout == "vertical"
        ? _c(
            VRow,
            { staticStyle: { height: "100%" }, attrs: { "no-gutters": "" } },
            [
              _c("LabLocatorChat", {
                staticStyle: { "z-index": "9999" },
                attrs: { "lab-info": _vm.labInfo },
              }),
              _c(
                VCol,
                [
                  _c(
                    VRow,
                    { staticStyle: { height: "300px", overflow: "auto" } },
                    [
                      _c(
                        VCol,
                        [
                          _c(
                            VRow,
                            {
                              staticStyle: {
                                position: "fixed",
                                top: "80px",
                                background: "#ffffff",
                                width: "100%",
                              },
                            },
                            [
                              _vm.isAdmin || _vm.myId == _vm.userId
                                ? _c(
                                    VBtn,
                                    {
                                      staticClass: "ma-2",
                                      staticStyle: {
                                        width: "100px",
                                        height: "36px",
                                      },
                                      attrs: { large: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.screenCapture()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            제출\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.isAdmin && _vm.labInfo.tool == "ide"
                                ? _c(
                                    VBtn,
                                    {
                                      staticClass: "ma-2",
                                      staticStyle: {
                                        width: "104px",
                                        height: "36px",
                                        "font-size": "12px",
                                      },
                                      attrs: { large: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.test()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            IDE 콘텐츠 저장\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("br"),
                              _c(
                                VBtn,
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    to:
                                      "/courses/" +
                                      _vm.courseId +
                                      "/" +
                                      _vm.classId +
                                      "/" +
                                      _vm.labId +
                                      "/class-room",
                                    small: "",
                                  },
                                },
                                [_vm._v("클래스룸\n                        ")]
                              ),
                              _c(
                                VBtn,
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    small: "",
                                    disabled: !_vm.loadAllgroupedLabsList,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.contextOpen()
                                    },
                                  },
                                },
                                [_vm._v("랩 목록\n                        ")]
                              ),
                              _c(
                                VDialog,
                                {
                                  attrs: { width: "500", height: "300" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              VBtn,
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "ma-2",
                                                    attrs: { small: "" },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                                    응답\n                                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    568183409
                                  ),
                                  model: {
                                    value: _vm.answerDialog,
                                    callback: function ($$v) {
                                      _vm.answerDialog = $$v
                                    },
                                    expression: "answerDialog",
                                  },
                                },
                                [
                                  _c(
                                    VCard,
                                    [
                                      _c(
                                        VCardTitle,
                                        {
                                          staticClass:
                                            "headline grey lighten-2",
                                          attrs: { "primary-title": "" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    응답\n                                "
                                          ),
                                        ]
                                      ),
                                      _c(VCardText, [_c("answer")], 1),
                                      _c(VDivider),
                                      _c(
                                        VCardActions,
                                        [
                                          _c(VSpacer),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.answerDialog = false
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        Close\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            VRow,
                            [
                              _c(
                                VList,
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(VSubheader, [
                                    _vm._v(
                                      "\n                                Instruction\n                            "
                                    ),
                                  ]),
                                  _c(
                                    VCol,
                                    [
                                      _vm.labInfo.instructionHtml
                                        ? _c("InstructionHtml", {
                                            staticClass: "markdown-body",
                                            attrs: {
                                              source:
                                                _vm.labInfo.instructionHtml,
                                            },
                                          })
                                        : _c("vue-markdown", {
                                            staticClass: "markdown-body",
                                            staticStyle: {
                                              "white-space": "pre",
                                            },
                                            attrs: {
                                              source: _vm.labInfo.instructionMd,
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                  _c(VDivider),
                                  _c(VSubheader, [
                                    _vm._v(
                                      "\n                                Checkpoints\n                            "
                                    ),
                                  ]),
                                  _vm.labInfo.tool == "quiz"
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          _vm.setNewCheckPoints,
                                          function (item, idx) {
                                            return _c(
                                              VListItem,
                                              { key: idx },
                                              [
                                                _c(
                                                  VListItemContent,
                                                  [
                                                    _c(VListItemTitle, [
                                                      _vm._v(
                                                        _vm._s(idx + 1) +
                                                          ". " +
                                                          _vm._s(
                                                            item.quizNumber
                                                          )
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  VListItemAction,
                                                  [
                                                    !(
                                                      item.javascript ||
                                                      item.regExp
                                                    ) &&
                                                    _vm.isAdmin &&
                                                    _vm.renderComponent
                                                      ? _c(VCheckbox, {
                                                          attrs: {
                                                            success: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.sendCheckPoints(
                                                                item
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value: item.status,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "status",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.status",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    item.javascript ||
                                                    item.regExp ||
                                                    (!_vm.isAdmin &&
                                                      _vm.renderComponent)
                                                      ? _c(VCheckbox, {
                                                          attrs: {
                                                            success: "",
                                                            readonly: "",
                                                          },
                                                          model: {
                                                            value: item.status,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "status",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.status",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _c(
                                        "div",
                                        _vm._l(
                                          _vm.labInfo.checkPoints,
                                          function (item, idx) {
                                            return _c(
                                              VListItem,
                                              { key: idx },
                                              [
                                                item.text
                                                  ? _c(
                                                      VListItemContent,
                                                      [
                                                        _c(
                                                          VListItemTitle,
                                                          [
                                                            _vm._v(
                                                              _vm._s(idx + 1) +
                                                                ". " +
                                                                _vm._s(
                                                                  item.text
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  VListItemAction,
                                                  [
                                                    !(
                                                      item.javascript ||
                                                      item.regExp
                                                    ) &&
                                                    _vm.isAdmin &&
                                                    _vm.renderComponent
                                                      ? _c(VCheckbox, {
                                                          attrs: {
                                                            success: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.sendCheckPoints(
                                                                item
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value: item.status,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "status",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.status",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    item.javascript ||
                                                    item.regExp ||
                                                    (!_vm.isAdmin &&
                                                      _vm.renderComponent)
                                                      ? _c(VCheckbox, {
                                                          attrs: {
                                                            success: "",
                                                            readonly: "",
                                                          },
                                                          model: {
                                                            value: item.status,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "status",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.status",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VRow,
                    [
                      _c("lab-tool-" + _vm.labInfo.tool, {
                        ref: "toolComp",
                        tag: "component",
                        staticStyle: {
                          height: "100% !important",
                          width: "100%",
                        },
                        attrs: {
                          "lab-info": _vm.labInfo,
                          "class-info": _vm.classInfo,
                          passAll: _vm.passAll,
                          basicPassed: _vm.basicPassed,
                          certi: _vm.certi,
                        },
                        on: { change: _vm.onResultChanged },
                        model: {
                          value: _vm.tool,
                          callback: function ($$v) {
                            _vm.tool = $$v
                          },
                          expression: "tool",
                        },
                      }),
                      _c("resize-observer", {
                        on: { notify: _vm.handleResize },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.labInfo
        ? _c(
            VRow,
            { staticStyle: { height: "100%", margin: "0px !important" } },
            [
              _c(
                VNavigationDrawer,
                {
                  staticStyle: { "z-index": "99" },
                  attrs: {
                    app: "",
                    clipped: "",
                    stateless: "",
                    width: _vm.naviSize,
                  },
                  model: {
                    value: _vm.guideOpened,
                    callback: function ($$v) {
                      _vm.guideOpened = $$v
                    },
                    expression: "guideOpened",
                  },
                },
                [
                  _c(
                    VDialog,
                    {
                      attrs: { width: "400" },
                      model: {
                        value: _vm.openVideoTextfield,
                        callback: function ($$v) {
                          _vm.openVideoTextfield = $$v
                        },
                        expression: "openVideoTextfield",
                      },
                    },
                    [
                      _c(
                        VCard,
                        { staticStyle: { height: "100%" } },
                        [
                          _c(
                            VCardTitle,
                            {
                              staticStyle: {
                                "background-color": "#0097e6",
                                color: "white",
                                "margin-left": "-10px",
                              },
                            },
                            [
                              _c(VIcon, { attrs: { color: "white" } }, [
                                _vm._v("mdi-link-variant"),
                              ]),
                              _vm._v(" Input Link\n                    "),
                            ],
                            1
                          ),
                          _c(
                            VCardText,
                            [
                              _c(VTextField, {
                                staticStyle: { "margin-top": "15px" },
                                attrs: {
                                  clearable: "",
                                  filled: "",
                                  label: "Youtube Link",
                                },
                                model: {
                                  value: _vm.video.url,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.video, "url", $$v)
                                  },
                                  expression: "video.url",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCardActions,
                            {
                              staticStyle: {
                                "justify-content": "flex-end",
                                "margin-top": "-40px",
                              },
                            },
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.openVideoTextfield = false
                                    },
                                  },
                                },
                                [_vm._v("cancel")]
                              ),
                              _c(
                                VBtn,
                                {
                                  attrs: { color: "primary", text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addVideo()
                                    },
                                  },
                                },
                                [_vm._v("save")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.labInfo.tool == "url"
                    ? _c("div", [_c("replay-player")], 1)
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _vm.labInfo && _vm.labInfo.video && _vm.renderComponent
                        ? _c("div", [
                            _c(
                              "div",
                              [
                                _c("youtube-media", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: { "video-id": _vm.getLabVideoId },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _c(
                            "div",
                            [
                              (!_vm.openVideoTextfield && _vm.isOwner) ||
                              _vm.isTeacher ||
                              _vm.isAdmin
                                ? _c(
                                    VCard,
                                    {
                                      staticStyle: {
                                        width: "auto",
                                        height: "180px",
                                        "background-color": "#dcdde1",
                                        "margin-bottom": "5px",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.openVideoTextfield = true
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            VIcon,
                                            {
                                              staticStyle: {
                                                "font-size": "40px",
                                                "margin-top": "64px",
                                                "background-color": "gray",
                                                width: "70px",
                                                height: "55px",
                                                color: "#dcdde1",
                                                "border-radius": "27%",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "mdi-play\n                            "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                      _c(
                        "div",
                        { staticClass: "lab-locator-fixed" },
                        [
                          _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _vm.isAdmin || _vm.myId == _vm.userId
                                        ? _c(
                                            VBtn,
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticStyle: {
                                                    "margin-left": "5px",
                                                  },
                                                  attrs: {
                                                    color: "primary",
                                                    icon: "",
                                                    large: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.resizeGuide()
                                                    },
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#1976d2",
                                                    "font-weight": "900",
                                                    "font-size": "26px",
                                                    "margin-top": "-4px",
                                                  },
                                                },
                                                [_vm._v("↹")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("인스트럭션 크기 조절")])]
                          ),
                          _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        VBtn,
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticStyle: {
                                                "margin-left": "5px",
                                              },
                                              attrs: {
                                                color: "primary",
                                                icon: "",
                                                large: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.answerDialog = true
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-comment-check-outline"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("응답")])]
                          ),
                          _vm.isOwner ||
                          _vm.isTeacher ||
                          (_vm.isAdmin && _vm.video.url)
                            ? _c(
                                VTooltip,
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              VBtn,
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "5px",
                                                    },
                                                    attrs: {
                                                      color: "primary",
                                                      icon: "",
                                                      large: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.editLabVideo()
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("Icon", {
                                                  staticStyle: {
                                                    "margin-top": "-4px",
                                                    width: "30px",
                                                    height: "30px",
                                                  },
                                                  attrs: {
                                                    icon: "material-symbols:video-settings-rounded",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1247304928
                                  ),
                                },
                                [_c("span", [_vm._v("유튜브 Url 수정")])]
                              )
                            : _vm._e(),
                          !_vm.opentextfield
                            ? _c(
                                VTooltip,
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _vm.isOwner ||
                                            _vm.isTeacher ||
                                            _vm.isAdmin
                                              ? _c(
                                                  VBtn,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "5px",
                                                        },
                                                        attrs: {
                                                          color: "primary",
                                                          icon: "",
                                                          large: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openMarkdown(
                                                              _vm.labInfo
                                                                .instructionMd
                                                            )
                                                          },
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      VIcon,
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "5px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-border-color"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3176587626
                                  ),
                                },
                                [_c("span", [_vm._v("인스트럭션 수정")])]
                              )
                            : _vm._e(),
                          _vm.savebtn
                            ? _c(
                                VTooltip,
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              VBtn,
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "5px",
                                                    },
                                                    attrs: {
                                                      icon: "",
                                                      large: "",
                                                      color: "primary",
                                                      disabled: _vm.loading,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.save(
                                                          _vm.instructionText
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(VIcon, [
                                                  _vm._v("mdi-content-save"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1547610299
                                  ),
                                },
                                [_c("span", [_vm._v("인스트럭션 저장")])]
                              )
                            : _vm._e(),
                          _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        VBtn,
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticStyle: {
                                                "margin-left": "5px",
                                              },
                                              attrs: {
                                                color: "primary",
                                                icon: "",
                                                large: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.uploadImage()
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(VIcon, [
                                            _vm._v(
                                              "\n                                    mdi-folder-upload\n                                "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("이미지 업로드")])]
                          ),
                          _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _vm.isAdmin || _vm.myId == _vm.userId
                                        ? _c(
                                            VBtn,
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticStyle: {
                                                    "margin-left": "5px",
                                                  },
                                                  attrs: {
                                                    color: "primary",
                                                    icon: "",
                                                    large: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.screenCapture()
                                                    },
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(VIcon, [
                                                _vm._v(_vm._s(_vm.setIcon)),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("제출")])]
                          ),
                          _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        VBtn,
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticStyle: {
                                                "margin-left": "5px",
                                              },
                                              attrs: {
                                                color: "primary",
                                                icon: "",
                                                large: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openClassRoom()
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(VIcon, [
                                            _vm._v(
                                              "\n                                    mdi-account-multiple\n                                "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("유저 목록")])]
                          ),
                          _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        VBtn,
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticStyle: {
                                                "margin-left": "5px",
                                              },
                                              attrs: {
                                                fill: "none",
                                                color: "primary",
                                                icon: "",
                                                large: "",
                                                disabled:
                                                  !_vm.loadAllgroupedLabsList,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.contextOpen()
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-view-headline"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("랩 목록")])]
                          ),
                          _c(
                            VDialog,
                            {
                              attrs: { width: "500", height: "300" },
                              model: {
                                value: _vm.answerDialog,
                                callback: function ($$v) {
                                  _vm.answerDialog = $$v
                                },
                                expression: "answerDialog",
                              },
                            },
                            [
                              _c(
                                VCard,
                                [
                                  _c(
                                    VCardTitle,
                                    {
                                      staticClass: "headline grey lighten-2",
                                      attrs: { "primary-title": "" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                응답\n                            "
                                      ),
                                    ]
                                  ),
                                  _c(VCardText, [_c("answer")], 1),
                                  _c(VDivider),
                                  _c(
                                    VCardActions,
                                    [
                                      _c(VSpacer),
                                      _c(
                                        VBtn,
                                        {
                                          attrs: { color: "primary", text: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.answerDialog = false
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Close\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        VChip,
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                color: _vm.attendance
                                                  ? "green darken-1"
                                                  : "red darken-1",
                                                "text-color": "white",
                                                small: "",
                                              },
                                            },
                                            "v-chip",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(_vm.labStateText) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _vm.attendance
                                ? _c("span", [_vm._v(" 출석처리 되었습니다")])
                                : _c("span", [
                                    _vm._v(
                                      "출석처리가 되려면 3분을 기다려야 합니다"
                                    ),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        VNavigationDrawer,
                        {
                          staticStyle: { width: "100%", "z-index": "100" },
                          attrs: { absolute: "", temporary: "" },
                          model: {
                            value: _vm.menuOpen,
                            callback: function ($$v) {
                              _vm.menuOpen = $$v
                            },
                            expression: "menuOpen",
                          },
                        },
                        [
                          _c(
                            VList,
                            { key: _vm.updateList },
                            [
                              _c(
                                VListItemGroup,
                                { attrs: { color: "primary" } },
                                [
                                  _c(
                                    VRow,
                                    { staticStyle: { margin: "0" } },
                                    [
                                      _c(
                                        VCol,
                                        { attrs: { clos: "10" } },
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              attrs: { text: "" },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.onClick(
                                                    _vm.listText
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(VIcon, [
                                                _vm._v("mdi-arrow-left"),
                                              ]),
                                              _c("b", [
                                                _vm._v(_vm._s(_vm.listText)),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "2" } },
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.contextOpen()
                                                },
                                              },
                                            },
                                            [
                                              _c(VIcon, [
                                                _vm._v("mdi-close"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(VDivider),
                                  _vm._l(
                                    _vm.classInfo.groupedLabsList,
                                    function (groupinfo, i) {
                                      return _c(
                                        "div",
                                        { key: i },
                                        [
                                          groupinfo &&
                                          groupinfo.labsList.length > 0 &&
                                          _vm.currentLabInfo
                                            ? _c(
                                                VListGroup,
                                                {
                                                  attrs: {
                                                    value:
                                                      groupinfo.groupName ==
                                                      _vm.currentLabInfo
                                                        .groupName
                                                        ? true
                                                        : false,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              VListItemTitle,
                                                              [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      groupinfo.groupName
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                _vm._l(
                                                  groupinfo.labsList,
                                                  function (lab, i) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        _vm.AlllabsList[
                                                          groupinfo.groupName
                                                        ] &&
                                                        _vm.AlllabsList[
                                                          groupinfo.groupName
                                                        ][lab]
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _vm.AlllabsList[
                                                                  groupinfo
                                                                    .groupName
                                                                ][lab].active ||
                                                                _vm.isAdmin
                                                                  ? _c(
                                                                      VListItem,
                                                                      {
                                                                        style:
                                                                          lab ==
                                                                          _vm
                                                                            .currentLabInfo
                                                                            .labId
                                                                            ? "background-color: #dcf0ff"
                                                                            : "",
                                                                        attrs: {
                                                                          link: "",
                                                                        },
                                                                        on: {
                                                                          mouseenter:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.mouseenterInLab =
                                                                                lab
                                                                            },
                                                                          mouseleave:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.mouseenterInLab =
                                                                                null
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          VListItemContent,
                                                                          {
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onClick(
                                                                                    lab
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              VListItemTitle,
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "font-size":
                                                                                      "13px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "li",
                                                                                  {
                                                                                    style:
                                                                                      _vm
                                                                                        .AlllabsList[
                                                                                        groupinfo
                                                                                          .groupName
                                                                                      ][
                                                                                        lab
                                                                                      ]
                                                                                        .active
                                                                                        ? ""
                                                                                        : "opacity: 0.4;",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                            " +
                                                                                        _vm._s(
                                                                                          _vm
                                                                                            .AlllabsList[
                                                                                            groupinfo
                                                                                              .groupName
                                                                                          ][
                                                                                            lab
                                                                                          ]
                                                                                            .labName
                                                                                        ) +
                                                                                        "\n                                                            "
                                                                                    ),
                                                                                    _vm
                                                                                      .AlllabsList[
                                                                                      groupinfo
                                                                                        .groupName
                                                                                    ][
                                                                                      lab
                                                                                    ]
                                                                                      .labStatus &&
                                                                                    _vm
                                                                                      .AlllabsList[
                                                                                      groupinfo
                                                                                        .groupName
                                                                                    ][
                                                                                      lab
                                                                                    ]
                                                                                      .labStatus
                                                                                      .status ==
                                                                                      "completed"
                                                                                      ? _c(
                                                                                          VChip,
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                "margin-left":
                                                                                                  "5px",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "green",
                                                                                                "text-color":
                                                                                                  "white",
                                                                                                "x-small":
                                                                                                  "",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "완료\n                                                            "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _vm
                                                                                      .AlllabsList[
                                                                                      groupinfo
                                                                                        .groupName
                                                                                    ][
                                                                                      lab
                                                                                    ]
                                                                                      .labStatus &&
                                                                                    _vm
                                                                                      .AlllabsList[
                                                                                      groupinfo
                                                                                        .groupName
                                                                                    ][
                                                                                      lab
                                                                                    ]
                                                                                      .labStatus
                                                                                      .status ==
                                                                                      "started"
                                                                                      ? _c(
                                                                                          VChip,
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                "margin-left":
                                                                                                  "5px",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "red",
                                                                                                "text-color":
                                                                                                  "white",
                                                                                                "x-small":
                                                                                                  "",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "진행중\n                                                            "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _vm
                                                                                      .AlllabsList[
                                                                                      groupinfo
                                                                                        .groupName
                                                                                    ][
                                                                                      lab
                                                                                    ]
                                                                                      .passMessage
                                                                                      ? _c(
                                                                                          VChip,
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                "margin-left":
                                                                                                  "5px",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  _vm
                                                                                                    .AlllabsList[
                                                                                                    groupinfo
                                                                                                      .groupName
                                                                                                  ][
                                                                                                    lab
                                                                                                  ]
                                                                                                    .passMessage ==
                                                                                                  "접속"
                                                                                                    ? "blue darken-1"
                                                                                                    : "green",
                                                                                                "text-color":
                                                                                                  "white",
                                                                                                "x-small":
                                                                                                  "",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                                " +
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .AlllabsList[
                                                                                                    groupinfo
                                                                                                      .groupName
                                                                                                  ][
                                                                                                    lab
                                                                                                  ]
                                                                                                    .passMessage
                                                                                                ) +
                                                                                                "\n                                                            "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          VListItemIcon,
                                                                          [
                                                                            _vm.mouseenterInLab ==
                                                                            lab
                                                                              ? _c(
                                                                                  VIcon,
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        "font-size":
                                                                                          "18px",
                                                                                      },
                                                                                    nativeOn:
                                                                                      {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.onClick(
                                                                                              lab,
                                                                                              "newTab"
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                        mdi-open-in-new\n                                                    "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              VListItem,
                                                              {
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onClick(
                                                                        lab
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("li", [
                                                                  _vm._v(
                                                                    _vm._s(lab)
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VList,
                        {
                          staticStyle: { "margin-bottom": "60px" },
                          attrs: { dense: "" },
                        },
                        [
                          _c(VDivider),
                          _vm.opentextfield
                            ? _c(
                                VCol,
                                { staticStyle: { "margin-top": "25px" } },
                                [
                                  _c("vue-simplemde", {
                                    ref: "markdownEditor",
                                    staticStyle: { overflow: "scroll" },
                                    model: {
                                      value: _vm.instructionText,
                                      callback: function ($$v) {
                                        _vm.instructionText = $$v
                                      },
                                      expression: "instructionText",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.opentextfield
                            ? _c(
                                VCol,
                                {
                                  style: _vm.loadInstruction
                                    ? "opacity: 0.4"
                                    : "",
                                },
                                [
                                  _vm.labInfo.instructionHtml
                                    ? _c("InstructionHtml", {
                                        attrs: {
                                          source: _vm.labInfo.instructionHtml,
                                        },
                                      })
                                    : _c("vue-markdown", {
                                        staticClass: "markdown-body",
                                        staticStyle: { margin: "0 10px 0 0px" },
                                        attrs: {
                                          source: _vm.labInfo.instructionMd,
                                        },
                                      }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(VDivider),
                          _c(
                            VSubheader,
                            [
                              _vm._v(
                                "\n                        Checkpoints\n                        "
                              ),
                              _c(
                                VCol,
                                [
                                  _vm.isAdmin &&
                                  !_vm.editCheckpoint &&
                                  _vm.labInfo.tool != "quiz"
                                    ? _c(
                                        VIcon,
                                        {
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openEditCheckpointField()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                mdi-border-color\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.labInfo.tool == "quiz"
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.setNewCheckPoints,
                                  function (item, idx) {
                                    return _c(
                                      VListItem,
                                      { key: idx },
                                      [
                                        _vm.editCheckpoint
                                          ? _c(VListItemContent, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(idx + 1) + "."
                                                    ),
                                                  ]),
                                                  _c(VTextField, {
                                                    staticStyle: {
                                                      "margin-top": "-20px",
                                                      "margin-left": "6px",
                                                    },
                                                    attrs: {
                                                      "append-outer-icon":
                                                        "mdi-minus-circle-outline",
                                                    },
                                                    on: {
                                                      "click:append-outer":
                                                        function ($event) {
                                                          return _vm.removeCheckpoint(
                                                            item
                                                          )
                                                        },
                                                    },
                                                    model: {
                                                      value: item.text,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "text",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.text",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ])
                                          : _c(
                                              VListItemContent,
                                              [
                                                _c(VListItemTitle, [
                                                  _vm._v(
                                                    _vm._s(idx + 1) +
                                                      ". " +
                                                      _vm._s(item.quizNumber)
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                        !_vm.editCheckpoint
                                          ? _c(
                                              VListItemAction,
                                              [
                                                _vm.isAdmin &&
                                                _vm.renderComponent
                                                  ? _c(VCheckbox, {
                                                      attrs: { success: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.sendCheckPoints(
                                                            item
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: item.status,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "status",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.status",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                !_vm.isAdmin &&
                                                _vm.renderComponent
                                                  ? _c(VCheckbox, {
                                                      attrs: {
                                                        success: "",
                                                        readonly: "",
                                                      },
                                                      model: {
                                                        value: item.status,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "status",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.status",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            : _c("div", [
                                _vm.editCheckpoint
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.copyCheckPoints,
                                        function (item, idx) {
                                          return _c(
                                            VListItem,
                                            { key: idx },
                                            [
                                              _c(VListItemContent, [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(idx + 1) + "."
                                                      ),
                                                    ]),
                                                    _c(VTextField, {
                                                      staticStyle: {
                                                        "margin-top": "-20px",
                                                        "margin-left": "6px",
                                                      },
                                                      attrs: {
                                                        "append-outer-icon":
                                                          "mdi-minus-circle-outline",
                                                      },
                                                      on: {
                                                        "click:append-outer":
                                                          function ($event) {
                                                            return _vm.removeCheckpoint(
                                                              item
                                                            )
                                                          },
                                                      },
                                                      model: {
                                                        value: item.text,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "text",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "item.text",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _c(
                                      "div",
                                      _vm._l(
                                        _vm.labInfo.checkPoints,
                                        function (item, idx) {
                                          return _c(
                                            VListItem,
                                            { key: idx },
                                            [
                                              _c(
                                                VListItemContent,
                                                [
                                                  item.text
                                                    ? _c(VListItemTitle, [
                                                        _vm._v(
                                                          _vm._s(idx + 1) +
                                                            ". " +
                                                            _vm._s(item.text) +
                                                            "\n                                    "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VListItemAction,
                                                [
                                                  _vm.isAdmin &&
                                                  _vm.renderComponent
                                                    ? _c(VCheckbox, {
                                                        attrs: { success: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.sendCheckPoints(
                                                              item
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value: item.status,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "status",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.status",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  !_vm.isAdmin &&
                                                  _vm.renderComponent
                                                    ? _c(VCheckbox, {
                                                        attrs: {
                                                          success: "",
                                                          readonly: "",
                                                        },
                                                        model: {
                                                          value: item.status,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "status",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.status",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                              ]),
                          _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _vm.editCheckpoint
                                ? _c(
                                    VIcon,
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.addNewCheckpoint()
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-plus-circle")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.editCheckpoint
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    float: "right",
                                    "margin-top": "10px",
                                    "margin-right": "10px",
                                  },
                                },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { text: "", small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.closeAddCheckPointField()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            cancel\n                        "
                                      ),
                                    ]
                                  ),
                                  _vm.updateCheckpointLoading
                                    ? _c(
                                        VIcon,
                                        { attrs: { color: "primary" } },
                                        [_vm._v("mdi-spin mdi-loading")]
                                      )
                                    : _c(
                                        VBtn,
                                        {
                                          attrs: {
                                            color: "primary",
                                            text: "",
                                            small: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateCheckpoints()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            save\n                        "
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.editCheckpoint
                            ? _c("div", [_c("br"), _c("br")])
                            : _vm._e(),
                          _c(VDivider),
                          (_vm.labInfo.hints && _vm.labInfo.hints.length > 0) ||
                          _vm.isAdmin
                            ? _c(
                                VSubheader,
                                [
                                  _vm._v(
                                    "\n                        Hints\n                        "
                                  ),
                                  _c(
                                    VCol,
                                    [
                                      _vm.isAdmin && !_vm.editHints
                                        ? _c(
                                            VIcon,
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.editHints = true
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                mdi-border-color\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.labInfo.hints
                            ? _c(
                                "div",
                                _vm._l(_vm.labInfo.hints, function (item, idx) {
                                  return _c(
                                    VListItem,
                                    { key: idx },
                                    [
                                      _vm.editHints
                                        ? _c(VListItemContent, [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _c("b", [
                                                  _vm._v(_vm._s(idx + 1) + "."),
                                                ]),
                                                _c(VTextField, {
                                                  staticStyle: {
                                                    "margin-top": "-20px",
                                                    "margin-left": "6px",
                                                  },
                                                  attrs: {
                                                    rules: _vm.validateRules,
                                                    "append-outer-icon":
                                                      "mdi-minus-circle-outline",
                                                  },
                                                  on: {
                                                    "click:append-outer":
                                                      function ($event) {
                                                        return _vm.removeHints(
                                                          idx
                                                        )
                                                      },
                                                  },
                                                  model: {
                                                    value: item.text,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "text",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.text",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _c(
                                            VListItemContent,
                                            [
                                              _c(VListItemTitle, [
                                                _vm._v(
                                                  _vm._s(idx + 1) +
                                                    ". " +
                                                    _vm._s(item.text)
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _vm.editHints
                                ? _c(
                                    VIcon,
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.addNewHint()
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-plus-circle")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                float: "right",
                                "margin-top": "10px",
                                "margin-right": "10px",
                              },
                            },
                            [
                              _vm.editHints
                                ? _c(
                                    VBtn,
                                    {
                                      attrs: { text: "", small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.closeAddHintsField()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            cancel\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.editHints
                                ? _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "primary",
                                        text: "",
                                        small: "",
                                        disabled: _vm.checkValidateHints,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateHints(
                                            _vm.labInfo,
                                            _vm.hints
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            save\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.editCheckpoint
                            ? _c("div", [_c("br"), _c("br")])
                            : _vm._e(),
                        ],
                        1
                      ),
                      (_vm.labInfo.hints && _vm.labInfo.hints.length > 0) ||
                      _vm.isAdmin
                        ? _c(VDivider)
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("LabLocatorChat", { attrs: { "lab-info": _vm.labInfo } }),
                ],
                1
              ),
              _vm.showClassroom
                ? _c(
                    VNavigationDrawer,
                    {
                      staticStyle: { "z-index": "3" },
                      style: _vm.openWideScreen
                        ? "width: 100%;"
                        : "width: 400px;",
                      attrs: { permanent: "", absolute: "", right: "" },
                    },
                    [
                      !_vm.openWideScreen
                        ? _c(
                            VBtn,
                            {
                              staticStyle: {
                                right: "50px",
                                "font-size": "20px",
                              },
                              attrs: {
                                disabled: _vm.loadingClassroom,
                                depressed: "",
                                absolute: "",
                                icon: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changedClassroomSize()
                                },
                              },
                            },
                            [_c(VIcon, [_vm._v("mdi-arrow-expand")])],
                            1
                          )
                        : _vm._e(),
                      _vm.openWideScreen
                        ? _c(
                            VBtn,
                            {
                              staticStyle: {
                                right: "50px",
                                "font-size": "20px",
                              },
                              attrs: { absolute: "", depressed: "", icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.changedClassroomSize()
                                },
                              },
                            },
                            [_c(VIcon, [_vm._v("mdi-keyboard-tab")])],
                            1
                          )
                        : _vm._e(),
                      _c(
                        VBtn,
                        {
                          staticStyle: { "font-size": "20px", right: "20px" },
                          attrs: { depressed: "", icon: "", absolute: "" },
                          on: {
                            click: function ($event) {
                              return _vm.closeUserList()
                            },
                          },
                        },
                        [_c(VIcon, [_vm._v("mdi-close")])],
                        1
                      ),
                      _vm.showClassroom ? _c("ClassRoom") : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticStyle: {
                    padding: "0px",
                    height: "100% !important",
                    width: "100%",
                  },
                },
                [
                  _c("div", { staticStyle: { height: "100%" } }, [
                    _c(
                      "div",
                      { staticStyle: { height: "100%" } },
                      [
                        _c("lab-tool-" + _vm.labInfo.tool, {
                          ref: "toolComp",
                          tag: "component",
                          attrs: {
                            passAll: _vm.passAll,
                            certi: _vm.certi,
                            basicPassed: _vm.basicPassed,
                            "lab-info": _vm.labInfo,
                            "class-info": _vm.classInfo,
                          },
                          on: { change: _vm.onResultChanged },
                          model: {
                            value: _vm.tool,
                            callback: function ($$v) {
                              _vm.tool = $$v
                            },
                            expression: "tool",
                          },
                        }),
                        _c("resize-observer", {
                          on: { notify: _vm.handleResize },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _c(
            VRow,
            [
              _c(
                VCol,
                [
                  _c(VSkeletonLoader, {
                    ref: "skeleton",
                    staticStyle: {
                      width: "20%",
                      height: "96.5%",
                      position: "absolute",
                      top: "10px",
                      left: "10px",
                    },
                    attrs: { indeterminate: "", type: "image" },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                [
                  _c(VSkeletonLoader, {
                    ref: "skeleton",
                    staticStyle: {
                      width: "78%",
                      height: "96.5%",
                      position: "absolute",
                      top: "10px",
                      left: "21.5%",
                    },
                    attrs: { indeterminate: "", type: "image" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        VSnackbar,
        {
          attrs: {
            timeout: "3000",
            "auto-height": "",
            color: _vm.snackBar.Color,
            "multi-line": "",
          },
          model: {
            value: _vm.snackBar.show,
            callback: function ($$v) {
              _vm.$set(_vm.snackBar, "show", $$v)
            },
            expression: "snackBar.show",
          },
        },
        [
          _c(
            VLayout,
            { attrs: { "align-center": "", "pr-4": "" } },
            [
              _vm.snackBar.icon
                ? _c(
                    VIcon,
                    { staticClass: "pr-3", attrs: { dark: "", large: "" } },
                    [_vm._v(_vm._s(_vm.snackBar.icon))]
                  )
                : _vm._e(),
              _c(VLayout, { attrs: { column: "" } }, [
                _vm.snackBar.title
                  ? _c("div", [
                      _c("strong", [_vm._v(_vm._s(_vm.snackBar.title))]),
                    ])
                  : _vm._e(),
                _c("div", [_vm._v(_vm._s(_vm.snackBar.Text))]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VSnackbar,
        {
          attrs: { color: "success", timeout: 2000, top: "" },
          model: {
            value: _vm.submitImageSnackbar,
            callback: function ($$v) {
              _vm.submitImageSnackbar = $$v
            },
            expression: "submitImageSnackbar",
          },
        },
        [_vm._v("제출되었습니다.\n    ")]
      ),
      _c(
        VDialog,
        {
          attrs: { width: "800" },
          model: {
            value: _vm.openResultDialog,
            callback: function ($$v) {
              _vm.openResultDialog = $$v
            },
            expression: "openResultDialog",
          },
        },
        [
          _c(
            VCard,
            { attrs: { width: "800" } },
            [
              _c(VCardTitle, [_vm._v("결과 제출")]),
              _c(
                "div",
                { staticStyle: { "text-align": "-webkit-center" } },
                [
                  _c(VImg, {
                    staticStyle: { width: "90%" },
                    attrs: { src: _vm.tmpImage },
                  }),
                ],
                1
              ),
              _c(
                VCardActions,
                { staticStyle: { "justify-content": "right" } },
                [
                  _c(
                    VBtn,
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.openResultDialog = false
                        },
                      },
                    },
                    [_vm._v("취소")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        disabled: !_vm.tmpImage,
                        color: "primary",
                        text: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitResultByUser(_vm.tmpImage)
                        },
                      },
                    },
                    [_vm._v("제출")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { width: "800" },
          model: {
            value: _vm.imageWindow,
            callback: function ($$v) {
              _vm.imageWindow = $$v
            },
            expression: "imageWindow",
          },
        },
        [
          _c(
            VCard,
            { attrs: { width: "800" } },
            [
              _c(VCardTitle, [_vm._v("결과 제출")]),
              _c(
                "div",
                { staticStyle: { "text-align": "-webkit-center" } },
                [
                  !_vm.imgFile
                    ? _c(
                        "div",
                        {
                          staticClass: "gs-info-bg",
                          staticStyle: { position: "relative", left: "8%" },
                        },
                        [
                          _c("div", [_vm._v("이미지 업로드 방법 선택")]),
                          _c("div", { staticClass: "gs-info-sub-bg" }, [
                            _c(
                              "div",
                              {
                                staticClass: "gs-info-sub-title",
                                staticStyle: {
                                  "text-align": "left",
                                  "margin-left": "10px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            1.클립보드 이미지 붙여넣기 ctrl + V"
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n                            2. 내 PC 이미지 클릭 후 업로드\n                        "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c(VImg, {
                    staticClass: "img-file-style",
                    staticStyle: { width: "90%", "max-height": "600px" },
                    attrs: { src: _vm.imgFile ? _vm.imgFile : null },
                  }),
                ],
                1
              ),
              _c(
                VCardActions,
                { staticStyle: { "justify-content": "right" } },
                [
                  _c(
                    VBtn,
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteImage()
                        },
                      },
                    },
                    [_vm._v("취소\n                ")]
                  ),
                  _c(
                    VBtn,
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.selectFile()
                        },
                      },
                    },
                    [_vm._v("내 PC 이미지")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.submitResultByUser(_vm.imgFile)
                        },
                      },
                    },
                    [_vm._v("제출\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "modal" }, [
        _c("div", { staticClass: "modalBox" }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }